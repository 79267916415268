// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // serviceUrl: 'http://18.220.112.225:3000/graphql',
  // serviceUrlHttp: 'http://18.220.112.225:3000',

  // Added new url for staging
  serviceUrl: 'https://api-stg.cpp-additiveminds.com/graphql',
  serviceUrlHttp: 'https://api-stg.cpp-additiveminds.com',

  // production: true,
  // serviceUrl: 'https://api.cpp-additiveminds.com/graphql',
  // serviceUrlHttp: 'https://api.cpp-additiveminds.com'
};
